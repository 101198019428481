import { AnyAction, Reducer } from 'redux'
import { parseError } from './utils'
import {
  SiteApiModel,
  TelemetryAlertViewModel,
  TelemetryType,
} from '../api/apiservice'
import {
  startStreaming,
  ConnectionError,
  ReceiveError,
  StreamingError,
} from './utils/streaming'
import { ModelState } from './ModelState'
import { ISubscription } from '@microsoft/signalr'
import { Site, mapSite } from './Site'
import { getSiteClient } from '../api/client'

export interface SiteState extends ModelState {
  readonly site?: Site
}

export const defaultSiteState: SiteState = {
  site: undefined,
}

const requestSiteType = 'REQUEST_SITE'
const receiveSiteType = 'RECEIVE_SITE'
const receiveSiteErrorType = 'RECEIVE_SITE_ERROR'

const requestTelemetryType = 'REQUEST_TELEMETRY'
const receiveTelemetryType = 'RECEIVE_TELEMETRY'
const receiveTelemetryErrorType = 'RECEIVE_TELEMETRY_ERROR'

export const GatewayAssetId = 'GatewayAssetId'

export const requestSite = async (
  accessToken: string,
  tenantId: string,
  id: string,
  getState: () => SiteState,
  setState: (state: SiteState) => void
) => {
  const dispatch = (action: AnyAction) => setState(reducer(getState(), action))

  dispatch({ type: requestSiteType })

  try {
    const { result } = await getSiteClient(accessToken, tenantId).get(id)
    dispatch({
      site: result,
      type: receiveSiteType,
    })
  } catch (error) {
    console.error(error)
    dispatch({
      error,
      type: receiveSiteErrorType,
    })
  }
}

export interface TelemetryMessage {
  readonly Id: string
  readonly AssetId: string
  readonly GatewayId: string
  readonly SiteId: string
  readonly TelemetryType: TelemetryType
  readonly CreationTimeUtc: Date
  readonly Payload: any
  readonly sensorBatteryLevel: string
  readonly invalidData: boolean
  readonly isSimulated: boolean
  readonly alert?: TelemetryAlertViewModel
}

type TelemetryMessageDTO = Record<keyof TelemetryMessage, any>

const mapTelemetry = (dto: TelemetryMessageDTO): TelemetryMessage => {
  return {
    Id: dto.Id,
    AssetId: dto.AssetId,
    GatewayId: dto.GatewayId,
    SiteId: dto.SiteId,
    TelemetryType: Number(dto.TelemetryType),
    CreationTimeUtc: new Date(dto.CreationTimeUtc),
    invalidData: dto.invalidData === 'true',
    isSimulated: dto.isSimulated === 'true',
    Payload: {
      status: dto.Payload?.Status ?? '',
      metaData: dto.Payload?.MetaData ?? {},
      value: dto.Payload?.Value,
      unitOfMeasure: dto.Payload?.UnitOfMeasure ?? '',
    },
    sensorBatteryLevel: dto.sensorBatteryLevel,
    alert: dto.alert ?? {},
  }
}

export interface RealtimeTelemetry {
  readonly realtimeDataMaxTimeStamp?: Date
  readonly data: TelemetryMessage[]
}

type TelemetryByTelemetryTypeAndAssetIdDictionary = {
  [key in TelemetryType]: Map<string, RealtimeTelemetry>
}

export type TelemetryByTelemetryTypeAndAssetId =
  Partial<TelemetryByTelemetryTypeAndAssetIdDictionary>

export interface TelemetryState extends ModelState {
  readonly subscription?: ISubscription<any>
  readonly telemetry: TelemetryByTelemetryTypeAndAssetId
}

export const requestSiteTelemetry = async (
  accessToken: string,
  tenantId: string,
  siteIds: string[],
  getState: () => TelemetryState,
  setState: (state: TelemetryState) => void
) => {
  const dispatch = (action: AnyAction) =>
    setState(telemetryReducer(getState(), action))

  dispatch({ type: requestTelemetryType })

  try {
    const result = await startStreaming(
      accessToken,
      tenantId,
      'telemetry',
      () =>
        dispatch({
          type: requestTelemetryType,
          loading: false,
        }),
      siteIds
    ).catch((error) => new ConnectionError(error))
    if (result instanceof Error) {
      console.error(result)
      dispatch({
        error: result,
        type: receiveTelemetryErrorType,
      })
      return
    }

    const subscription = result.subscribe({
      next: (data) => {
        const obj: TelemetryMessageDTO = JSON.parse(data)
        if (siteIds.includes(obj.SiteId)) {
          try {
            const telemetry = mapTelemetry(obj)
            dispatch({
              telemetry,
              type: receiveTelemetryType,
            })
          } catch (e) {
            console.error('Error processing telemetry:', e)
            dispatch({
              error: new ReceiveError(e),
              type: receiveTelemetryErrorType,
            })
          }
        }
      },
      error: (error) => {
        console.error('Subscription error:', error)
        dispatch({
          error: new ReceiveError(error),
          type: receiveTelemetryErrorType,
        })
      },
      complete: () => {
        dispatch({
          type: requestTelemetryType,
          loading: false,
        })
      },
    })

    dispatch({
      subscription,
      type: receiveTelemetryType,
    })
  } catch (error) {
    console.error(error)
    dispatch({
      error: new StreamingError(error),
      type: receiveTelemetryErrorType,
    })
  }
}

const reducer: Reducer<SiteState> = (
  state = defaultSiteState,
  action: AnyAction
) => {
  switch (action.type) {
    case requestSiteType:
      return {
        ...state,
        error: undefined,
        loading: true,
      }

    case receiveSiteType:
      const desc: SiteApiModel = action.site
      const site = desc ? mapSite(desc) : undefined

      return {
        ...state,
        site,
        loading: false,
        error: undefined,
      }

    case receiveSiteErrorType:
      return {
        ...state,
        error: parseError(action.error),
        loading: false,
      }

    default:
      return state
  }
}

export const defaultTelemetryState: TelemetryState = { telemetry: {} }

export function processTelemetryMessage(
  telemetry: TelemetryByTelemetryTypeAndAssetId,
  newlyArrivedMessage: TelemetryMessage
): TelemetryByTelemetryTypeAndAssetId {
  const {
    TelemetryType: newlyArrivedMessageTelType,
    CreationTimeUtc: newlyArrivedMessageCreationTime,
  } = newlyArrivedMessage

  const newlyArrivedMessageAssetId =
    newlyArrivedMessageTelType !== TelemetryType.GatewayMetrics
      ? newlyArrivedMessage.AssetId
      : GatewayAssetId

  const prevRealtimeTelemetry =
    telemetry[newlyArrivedMessageTelType] ??
    new Map<string, RealtimeTelemetry>()
  const prevRealtimeTelemetryForCurrAsset: RealtimeTelemetry =
    prevRealtimeTelemetry.get(newlyArrivedMessageAssetId) ?? { data: [] }

  if (
    newlyArrivedMessageCreationTime.valueOf() >
    (prevRealtimeTelemetryForCurrAsset?.realtimeDataMaxTimeStamp?.valueOf() ??
      0)
  ) {
    prevRealtimeTelemetry.set(newlyArrivedMessageAssetId, {
      realtimeDataMaxTimeStamp: newlyArrivedMessageCreationTime,
      data: prevRealtimeTelemetryForCurrAsset.data.concat(newlyArrivedMessage),
    } as RealtimeTelemetry)

    return {
      ...telemetry,
      [newlyArrivedMessageTelType]: prevRealtimeTelemetry,
    }
  }

  return telemetry
}

export function processTelemetryMessageGateway(
  newlyArrivedMessages: TelemetryMessage[]
): TelemetryByTelemetryTypeAndAssetId[] {
  const dictionary: TelemetryByTelemetryTypeAndAssetId[] = []
  newlyArrivedMessages.forEach((newlyArrivedMessage) => {
    const {
      TelemetryType: newlyArrivedMessageTelType,
      CreationTimeUtc: newlyArrivedMessageCreationTime,
    } = newlyArrivedMessage

    const newlyArrivedMessageAssetId = newlyArrivedMessage.AssetId

    const prevRealtimeTelemetry = new Map<string, RealtimeTelemetry>()
    const prevRealtimeTelemetryForCurrAsset: RealtimeTelemetry =
      prevRealtimeTelemetry.get(newlyArrivedMessageAssetId) ?? { data: [] }

    prevRealtimeTelemetry.set(newlyArrivedMessageAssetId, {
      realtimeDataMaxTimeStamp: newlyArrivedMessageCreationTime,
      data: prevRealtimeTelemetryForCurrAsset.data.concat(newlyArrivedMessage),
    } as RealtimeTelemetry)

    dictionary.push({ [newlyArrivedMessageTelType]: prevRealtimeTelemetry })
  })
  return dictionary
}

export const telemetryReducer: Reducer<TelemetryState> = (
  state = defaultTelemetryState,
  action: AnyAction
): TelemetryState => {
  switch (action.type) {
    case requestTelemetryType:
      return {
        ...state,
        error: undefined,
        loading: action.loading,
        subscription: undefined,
      }

    case receiveTelemetryType:
      const telemetryMessage: TelemetryMessage = action.telemetry
      const telemetry = telemetryMessage
        ? processTelemetryMessage(state.telemetry, telemetryMessage)
        : state.telemetry

      return {
        ...state,
        telemetry,
        subscription: state.subscription || action.subscription,
        loading: false,
      }

    case receiveTelemetryErrorType:
      return {
        ...state,
        error: parseError(action.error),
        loading: false,
      }

    default:
      return state
  }
}
